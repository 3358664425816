<template>
  <div class="login-page">
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <div class="image">
          <!-- <img src="../assets/images/logo.png" alt="main logo" /> -->
          <vuetify-logo />
        </div>
        <div class="page-holder">
          <p class="page-title">اختار طريقه الدفع</p>
          <div class="tabs">
            <div
              :class="
                payment_method == 'cash_on_delivery' ? 'active tab' : ' tab'
              "
              @click="changeTab('cash_on_delivery')"
              v-if="order.order_type != 'outRyad'"
            >
              <div class="tab-title">
                <div class="icon-tab">
                  <img
                    src="../assets/images/icons/1.png"
                    alt="الدفع عند الاستلام"
                  />
                </div>
                <div class="text-tab">الدفع عند الاستلام</div>
              </div>
            </div>
            <!-- /tab -->
            <!-- <div
              :class="payment_method == 'credit_card' ? 'active tab' : ' tab'"
              @click="changeTab('credit_card')"
            >
              <div class="tab-title">
                <div class="icon-tab">
                  <img src="../assets/images/icons/2.png" alt="بطاقه ائتمان" />
                </div>
                <div class="text-tab">بطاقه ائتمان</div>
              </div>
            </div> -->
            <!-- /tab -->

            <!-- /tab -->
            <!-- <div
              :class="payment_method == 'stc_pay' ? 'active tab' : ' tab'"
              @click="changeTab('stc_pay')"
            >
              <div class="tab-title">
                <div class="icon-tab">
                  <img src="../assets/images/icons/3.png" alt="STC Pay" />
                </div>
                <div class="text-tab">STC Pay</div>
              </div>
            </div> -->
            <!-- /tab -->
            <!-- /tab -->
            <div
              :class="payment_method == 'bank_transfer' ? 'active tab' : ' tab'"
              @click="changeTab('bank_transfer')"
            >
              <div class="tab-title">
                <div class="icon-tab">
                  <img
                    src="../assets/images/icons/4.png"
                    alt="ارسال حوالة بنكية"
                  />
                </div>
                <div class="text-tab">ارسال حوالة بنكية</div>
              </div>
              <br />
              <div class="bank-form" v-if="showForm">
                <p>
                  بإمكانك إتمام الطلب ورفع صورة التحويل لاحقاً من خلال الدخول
                  على قائمة طلباتي
                </p>
                <v-form class="form-full" ref="transferForm" v-model="valid">
                  <v-row>
                    <v-col cols="12" md="12">
                      <lable class="lable">اسم صاحب التحويل</lable>

                      <v-text-field
                        class="custom-input"
                        v-model="bankTransferData.name"
                        solo
                        label="اسم صاحب التحويل"
                        :rules="[validationRules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <lable class="lable">البنك</lable>

                      <v-select
                        :items="banks"
                        class="custom-input select"
                        v-model="bankTransferData.bank_account_id"
                        item-text="name"
                        item-value="id"
                        solo
                        :rules="[validationRules.required]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <input
                        id="myFileBrowser"
                        style="display: none !important"
                        type="File"
                        ref="file"
                        @change="readURL()"
                      />

                      <label
                        class="btn-attach-files custom-btn-attach-file"
                        @click="openFileInput()"
                      >
                        <!-- <span>رفع ملف</span> -->
                        {{ fileselect }}
                      </label>
                      <div
                        v-if="errorFile"
                        class="text-error"
                        style="color: red"
                      >
                        لا بد من ارفاق صورة التحويل
                      </div>
                    </v-col>
                    <hr />
                  </v-row>
                  <div class="card">
                    <div v-for="(item, i) in banks" :key="i" class="item-info">
                      <p class="">{{ item.name }}</p>
                      <p class="">{{ item.number }}</p>
                      <p>SA {{ item.iban }}</p>
                      <hr />
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
            <!-- /tab -->
            <a
              @click="submit()"
              v-if="payment_method != ''"
              class="btn complete-btn"
              >اكمال الطلب</a
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { API_URL } from "../config";
import VuetifyLogo from "../components/VuetifyLogo.vue";
import { ServiceFactory } from "../services/ServiceFactory";
import { validationMixin } from "../mixins/validationMixin";
const service = ServiceFactory.get("bank");
export default {
  mixins: [validationMixin],
  data() {
    return {
      paid: false,
      dataLoading: true,
      order: "",
      payment_method: "",
      showForm: false,
      banks: [],
      fileselect: "اسم الملف",
      bankTransferData: {
        name: "",
        bank_account_id: "",
        file: null,
      },
      valid: false,
      errorFile: false,
    };
  },
  components: {
    VuetifyLogo,
  },

  methods: {
    async submit() {
      if (this.payment_method == "cash_on_delivery") {
        this.selectOrderPaymentMethod();
      } else {
        this.uploadDataTransfer();
      }
    },
    async selectOrderPaymentMethod() {
      this.$http
        .get(
          `${API_URL}/checkout/payment_method/` +
            this.$route.query.orderId +
            "?payment_method=" +
            this.payment_method,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.$router.push("/thankYou/" + this.$route.query.orderId);
          }
        });
    },
    async readURL() {
      this.fileselect = this.$refs.file.files[0].name;
      this.bankTransferData.file = this.$refs.file.files[0];
    },
    async openFileInput() {
      document.getElementById("myFileBrowser").click();
    },
    async fetchAllItems() {
      this.dataLoading = true;
      const data = await service.getAllBanks();
      this.banks = data;
      this.dataLoading = false;
      if (this.$route.query.confirm != undefined) {
        this.payment_method = "bank_transfer";
        this.showForm = true;
      }
    },
    async changeTab(method) {
      if (method == "bank_transfer") {
        this.showForm = true;
      } else {
        this.showForm = false;
      }
      this.payment_method = method;
    },
    async uploadDataTransfer() {
      // console.log(this.valid);
      if (!this.valid) {
        if (
          this.bankTransferData.file ||
          this.bankTransferData.name != "" ||
          this.bankTransferData.bank_account_id != ""
        ) {
          this.$refs.transferForm.validate();
          if (!this.bankTransferData.file) {
            this.errorFile = true;
          }
        } else {
          this.selectOrderPaymentMethod();
        }
      } else {
        if (this.bankTransferData.file) {
          this.dataLoading = true;
          const data = new FormData();
          data.set("attachment", this.bankTransferData.file);
          data.set("name", this.bankTransferData.name);
          data.set("bank_account_id", this.bankTransferData.bank_account_id);
          this.$http
            .post(
              `${API_URL}/bank_transfer/` + this.$route.query.orderId,
              data,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then((response) => {
              if (response.data.status.error == false) {
                this.$router.push("/thankYou/" + this.$route.query.orderId);
              }
            });
        } else {
          this.dataLoading = false;
          this.errorFile = true;
        }
      }
    },
    checkPaymentStatus() {
      const resource = `${API_URL}/order/` + this.$route.query.orderId;
      this.$http
        .get(resource, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.order = response.data.data;
          this.dataLoading = false;
        });
    },
  },
  created() {
    this.checkPaymentStatus();
    this.fetchAllItems();
  },
  mounted() {},
};
</script>
<style>
.login-page {
  width: 100%;
}
.tab {
  margin-bottom: 30px;
  cursor: pointer;
  /* display: flex;
  align-items: center; */
}
.form-full .lable.lable {
  text-align: right;
  font-weight: 700;
  margin: 0 0 10px;
  display: block;
}
.tab .tab-title {
  overflow: hidden;
}
.tab .tab-title .icon-tab {
  background: #fff;
  color: #fff;
  width: 20%;
  height: 80px;
  font-size: 40px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  z-index: 1;
  /* display: inline-block; */
  position: relative;
  float: right;
}
.tab .tab-title .icon-tab img {
  width: 50px;
  margin-top: 15%;
}
.tab .tab-title .text-tab {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background-color: #fff;
  direction: ltr;
  width: 80%;
  margin-top: 5%;
  text-align: right;
  padding: 9px;
  border-radius: 12px 0 0 12px;
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-size: 15px;
  transition: 0.2s ease;

  z-index: 0;
  position: relative;
  float: right;
}
.tab.active .tab-title .text-tab {
  background: #fec113;
  color: #fff;
}

.custom-input input {
  max-height: 40px !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  overflow: hidden !important;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18) !important;
  border-radius: 13px !important;
  display: flex !important;
  background: #fff;
}
.select
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background: #eee !important;
}
.card {
  margin-top: 40px;
}
.card p {
  font-size: 14px !important;
  color: #5a5a5a !important;
}
.complete-btn {
  display: block !important;
  text-align: center !important;
  background-color: #4c9e53 !important;
  color: #fff !important;
  border-radius: 37px !important;
  padding: 9px !important;
  cursor: pointer !important;
  text-decoration: none !important;
  border: none !important;
  font-family: "frutiger lt arabic 55 roman" !important;
  margin: 0 auto !important;
  width: 91% !important;
}
</style>
